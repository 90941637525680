var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"hero-container"},[(_vm.riasocVisible)?_c('riasoc-hero'):_vm._e()],1),_c('div',{staticClass:"body-container text-center"},[_c('h2',[_vm._v(" Match jouw persoonlijkheid (Kiesreistype) aan een tussenjaar, zodat jij je optimaal kan ontwikkelen. ")]),_c('div',{staticClass:"image-container"},[_vm._m(0),_vm._m(1),_c('button',{staticClass:"primary-button-orange hidden-on-large-screen",on:{"click":function($event){return _vm.$router.push(
          {
            name: 'TestEnvironment',
            params: {
              type: _vm.currentTestTypeForSecondaryButton
            }
          }
        )}}},[_vm._v(" Start de test ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-img-container"},[_c('img',{attrs:{"src":require("@/assets/images/home/duration-image.png"),"alt":"Lengte van de test"}}),_c('p',[_vm._v("De test duurt 3-5 minuten.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-img-container"},[_c('img',{attrs:{"src":require("../assets/images/home/account-image.png"),"alt":"Account"}}),_c('p',[_vm._v(" In jouw gratis Kiesreisaccount heb je toegang tot de testuitslag en de mogelijkheden voor een tussenjaar! ")])])}]

export { render, staticRenderFns }