/* istanbul ignore file */
import Constants from '../constants';

import { auth } from '../../../Shared/lib/firebase';
import { UserAuthAPI } from '../../../Shared/index';

class Authentication {
  static popup = null;

  static authenticateIfRequired() {
    return new Promise((resolve, reject) => {
      if (Authentication.popup) {
        reject(new Error('Popup already open'));
        return;
      }

      if (this.getCsstCookie() === null) {
        let authUrl;
        if (window.location.href.includes('localhost')) {
          authUrl = Constants.DEV_AUTH_WEB_BASE_URL;
        } else {
          authUrl = Constants.AUTH_WEB_BASE_URL;
        }

        console.log('OPEN POPUP');
        Authentication.popup = window.open(
          authUrl,
          'popUpWindow',
          'height=800,width=500,left=100,top=100,resizable=yes,'
          + 'scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes',
        );

        let timeoutCounter = 0;

        const popupTick = setInterval(() => {
          if (timeoutCounter > 1200) { // 10 minutes
            clearInterval(popupTick);
            reject(new Error('Login timeout'));
          }

          if (Authentication.popup === null) {
            clearInterval(popupTick);
            reject(new Error('No Popup'));
            return;
          }

          timeoutCounter += 1;

          if (Authentication.popup.closed) {
            clearInterval(popupTick);
            this.popup = null;
            this.handleInitForAuth()
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          }
        }, 500);
      } else {
        this.handleInitForAuth()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  static userIsSignedIn() {
    return auth.currentUser !== null;
  }

  static signInIfCsstTokenPresent() {
    return new Promise((resolve, reject) => {
      if (Authentication.popup) {
        resolve();
        return;
      }

      if (this.getCsstCookie() && !Authentication.userIsSignedIn()) {
        this.handleInitForAuth()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve();
      }
    });
  }

  static handleInitForAuth() {
    return new Promise((resolve, reject) => {
      const csstCookie = this.getCsstCookie();
      if (csstCookie === null) {
        reject(new Error('The CSST Cookie is null'));
        return;
      }

      UserAuthAPI.checkAuthStatus(csstCookie)
        .then((checkAuthResponse) => {
          auth.signInWithCustomToken(checkAuthResponse.data.customToken)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCsstCookie() {
    // Check for the CSST cookie
    const cookies = document.cookie.split(';');
    if (cookies.length === 0) return null;

    let csstCookie = null;
    cookies.forEach((cookie) => {
      if (cookie.includes('csst')) {
        csstCookie = cookie;
      }
    });

    if (csstCookie !== null) csstCookie = csstCookie.replace('csst=', '');
    return csstCookie;
  }

  static signOut() {
    return new Promise((resolve, reject) => {
      UserAuthAPI.signOut()
        .then(() => {
          auth.signOut()
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export {
  Authentication,
}