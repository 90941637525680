<template lang="html">
  <div class="navbar-container" :class="{
    'navbar-container-expanded': (userId != '' && userId != null),
  }">
    <router-link :to="{ name: 'Home' }">
      <img src="@/assets/images/kiesreis-logo.png" alt="Kiesreis Logo">
    </router-link>

    <router-link
      v-if="userId == '' || userId == null"
      :to="{ name: 'Account' }"
      class="icon-container"
    >
      <icon class="icon" name="sign-in-alt"/>
      <p class="icon-subtitle">Inloggen</p>
    </router-link>

    <a
      v-if="userId != '' && userId != null"
      :href="getAccountUrl()"
      target="_blank"
      class="icon-container"
    >
      <icon class="icon" name="user-circle"/>
      <p class="icon-subtitle">Account</p>
    </a>

    <router-link
      v-if="userId != '' && userId != null"
      :to="{ name: 'Results', params: { testIdentifier: 'riasoc_kiesreis' }, }"
      class="icon-container"
    >
      <icon class="icon" name="poll"/>
      <p class="icon-subtitle">Resultaten</p>
    </router-link>

    <button class="icon-container" v-if="userId != '' && userId != null" @click="logout">
      <icon class="icon" name="sign-out-alt"/>
      <p class="icon-subtitle">Uitloggen</p>
    </button>
  </div>
</template>

<script>
import icon from 'vue-awesome/components/Icon.vue';
import { mapGetters } from 'vuex';
import Constants from '@/constants';
import { Authentication } from '../../../Shared/index';

export default {
  name: 'NavigationBar',
  methods: {
    logout() {
      Authentication.signOut()
        .then(() => {
          if (this.$router.currentRoute.name !== 'Home') {
            this.$router.push({ name: 'Home' });
          }
          this.$store.dispatch('riasocResults/flushData');
        });
    },
    getAccountUrl() {
      if (window.location.href.includes('localhost')) {
        return Constants.DEV_MIJN_KIESWIJS_BASE_URL;
      }
      return Constants.MIJN_KIESWIJS_BASE_URL;
    },
  },
  components: {
    icon,
  },
  computed: {
    ...mapGetters({
      userId: 'user/id',
    }),
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.navbar-container
  @include flex-row(center)
  background-color: white
  border-radius:  0 0 1em 0
  height: $navigation-bar-height - 5px
  left: 0
  position: absolute
  top: 0
  width: (2*$navigation-bar-height) - 5px

.navbar-container-expanded
  width: (4*$navigation-bar-height) - 5px

img
  height: 100%
  width: $navigation-bar-height - 5px
  object-fit: contain

.icon-container
  @include basicButton
  @include flex-column(center)
  padding: 0
  margin: 0
  align-items: center
  height: 100%
  width: $navigation-bar-height - 5px
  background-color: transparent

.icon
  color: $blue
  width: 25px
  height: 25px

.icon-subtitle
  margin: 2px 0 0 0 !important
  font-size: 8px !important

.icon-container
  text-decoration: none !important
  color: $blue
</style>
