export default Object.freeze({
  MAX_FILE_SIZE: 2621440,
  AUTH_WEB_BASE_URL: 'https://authenticatie.kieswijs.nl',
  FIREBASE_FUNCTIONS_BASE_URL: 'https://firebase.kieswijs.nl/api',
  MIJN_KIESWIJS_BASE_URL: 'https://mijn.kieswijs.nl/',
  KIESREIS_BASE_URL: 'https://www.kiesreis.com/',

  DEV_AUTH_WEB_BASE_URL: 'http://localhost:8080/',
  DEV_FIREBASE_FUNCTIONS_BASE_URL: 'http://localhost:5001/kieswijs-5e1a6/us-central1',
  DEV_MIJN_KIESWIJS_BASE_URL: 'http://localhost:8081/',
});
