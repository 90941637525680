import { render, staticRenderFns } from "./UnknownResultsHeader.vue?vue&type=template&id=448e4ecf&scoped=true&"
import script from "./UnknownResultsHeader.vue?vue&type=script&lang=js&"
export * from "./UnknownResultsHeader.vue?vue&type=script&lang=js&"
import style0 from "./UnknownResultsHeader.vue?vue&type=style&index=0&id=448e4ecf&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "448e4ecf",
  null
  
)

export default component.exports