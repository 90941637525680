<template>
  <div
    v-if="isShowing"
    ref="interactElement"
    class="card"
    :class="{
      isAnimating: isInteractAnimating,
      isCurrent: isCurrent
    }"
    :style="{ transform: transformString }"
  >
    <img
      class="card-image"
      :src="card.imageUrl"
      :alt="card.imageAlt"
    />
    <div class="card-text-container" ref="cardTitleContainer">
      <h1 class="card-title" ref="cardTitle">
        {{ card.question }}
      </h1>
    </div>
  </div>
</template>

<script>
import interact from 'interactjs';

const ACCEPT_CARD = 'cardAccepted';
const REJECT_CARD = 'cardRejected';

export default {
  name: 'GameCard',
  props: {
    card: {
      type: Object,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      interactPosition: {
        x: 0,
        y: 0,
        rotation: 0,
      },
      isInteractAnimating: true,
      isInteractDragged: null,
      isShowing: true,
    };
  },
  computed: {
    transformString() {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        const { x, y, rotation } = this.interactPosition;
        return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
      }
      return null;
    },
  },
  methods: {
    interactSetPosition(coordinates) {
      const { x = 0, y = 0, rotation = 0 } = coordinates;
      this.interactPosition = { x, y, rotation };
    },
    interactUnsetElement() {
      interact(this.$refs.interactElement).unset();
      this.isInteractDragged = true;
    },
    resetCardPosition() {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
    },
    hideCard() {
      setTimeout(() => {
        this.isShowing = false;
        this.$emit('hideCard', this.card);
      }, 300);
    },
    playCard(interaction) {
      const { interactOutOfSightXCoordinate, interactMaxRotation } = this.$options.static;

      this.interactUnsetElement();

      switch (interaction) {
        case ACCEPT_CARD:
          this.interactSetPosition({
            x: interactOutOfSightXCoordinate,
            rotation: interactMaxRotation,
          });
          this.$emit(ACCEPT_CARD);
          break;
        case REJECT_CARD:
          this.interactSetPosition({
            x: -interactOutOfSightXCoordinate,
            rotation: -interactMaxRotation,
          });
          this.$emit(REJECT_CARD);
          break;
        default:
          break;
      }
      this.hideCard();
    },
    sizeFontToFit() {
      const { maxFontSize, minFontSize } = this.$options.static;
      const titleElement = this.$refs.cardTitle;
      const containerElement = this.$refs.cardTitleContainer;

      // base height
      titleElement.style.fontSize = `${maxFontSize}px`;

      let titleHeight = titleElement.offsetHeight;
      let containerHeight = containerElement.offsetHeight;
      let currentSize = maxFontSize;

      let owerflowCount = 32;
      while (titleHeight > containerHeight
        && owerflowCount >= 0
        && currentSize >= minFontSize
      ) {
        titleElement.style.fontSize = `${(currentSize - 1)}px`;
        titleHeight = titleElement.offsetHeight;
        containerHeight = containerElement.offsetHeight;
        currentSize -= 1;
        owerflowCount -= 1;
      }
    },
  },
  mounted() {
    this.sizeFontToFit();
    const element = this.$refs.interactElement;

    interact(element).draggable({
      onstart: () => {
        this.isInteractAnimating = false;
      },
      onmove: (event) => {
        const x = this.interactPosition.x + event.dx;
        const y = this.interactPosition.y + event.dy;

        const { interactMaxRotation, interactXThreshold } = this.$options.static;
        let rotation = interactMaxRotation * (x / interactXThreshold);

        if (rotation > interactMaxRotation) {
          rotation = interactMaxRotation;
        } else if (rotation < -interactMaxRotation) {
          rotation = -interactMaxRotation;
        }

        this.interactSetPosition({ x, y, rotation });
      },
      onend: () => {
        const { x } = this.interactPosition;
        const { interactXThreshold } = this.$options.static;
        this.isInteractAnimating = true;

        if (x > interactXThreshold) {
          this.playCard(ACCEPT_CARD);
        } else if (x < -interactXThreshold) {
          this.playCard(REJECT_CARD);
        } else {
          this.resetCardPosition();
        }
      },
    });
  },
  beforeDestroy() {
    interact(this.$refs.interactElement).unset();
  },
  static: {
    interactMaxRotation: 15,

    // The X position of where the card has to be moved to after action
    interactOutOfSightXCoordinate: 500,

    // The distance a card should be moved to be accepted, rejected or skipped
    interactXThreshold: 100,

    maxFontSize: 32,
    minFontSize: 15,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

$cardsTotal: 3
$cardsPositionOffset: 1.5em
$cardsScaleOffset: 0.08
$defaultTranslation: $cardsPositionOffset * $cardsTotal
$defaultScale: 1 - ($cardsScaleOffset * $cardsTotal)

.card
  @include card()

  position: absolute
  width: 100%
  height: 90%
  margin: auto

  opacity: 0
  transform: translateY($defaultTranslation) scale($defaultScale)
  transform-origin: 50%, 100%
  will-change: transform, opacity

  &.isCurrent
    pointer-events: auto

  &.isAnimating
    transition: transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275)

@for $i from 1 through $cardsTotal
  $index: $i - 1
  $translation: $cardsPositionOffset * $index
  $scale: 1 - ($cardsScaleOffset * $index)

  .card:nth-child(#{$i})
    z-index: $cardsTotal - $index
    opacity: 1
    transform: translateY($translation) scale($scale)

    @if $i == 3
      color: white
      background-color: white
    @else if $i == 2
      color: white
      background-color: white

    @if $i != 1
      & .card-image
        opacity: 0.5

      & .card-title
        opacity: 0.5

.card-image
  border-radius: $cardBorderRadius $cardBorderRadius 0 0
  height: 80%
  object-fit: cover
  width: 100%

.card-text-container
  height: 20%
  margin: -0.2em 0
  @include flex-column(center)

.card-title
  color: $blue
  margin: 10px
  text-align: center

</style>
