import { jsPDF } from "jspdf";

const translateLetterToRiasoc = (letter) => {
  switch (letter) {
    case 'R':
      return 'Realist';
    case 'I':
      return 'Inhoudelijk';
    case 'A':
      return 'Artistiek';
    case 'S':
      return 'Sociaal';
    case 'O':
      return 'Ondernemend';
    case 'C':
      return 'Conventioneel';
    default:
      return 'Unk';
  }
};

const getXPositionOfCircleLabel = (letter) => {
  switch (letter) {
    case 'R':
      return 72;
    case 'I':
      return 74;
    case 'A':
      return 72;
    case 'S':
      return 72;
    case 'O':
      return 72;
    case 'C':
      return 72;
    default:
      return 0;
  }
};

/* istanbul ignore next */
const addBars = (doc, riasocResults) => {
  const circleYPositions = [98, 113, 128, 143, 158, 173];
  const barYPositions = [93, 108, 123, 138, 153, 168];
  const getPrefixLabelYPosition = [100, 115, 130, 145, 160, 175];

  const scoreArray = [];
  riasocResults.forEach((element) => { scoreArray.push(element); });
  const max = Math.max(...scoreArray);

  let index = 0;
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const value of riasocResults) {
    const score = value[1];

    doc.setTextColor(0, 0, 0); // Orange
    const fullName = translateLetterToRiasoc(value[0]);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(20);
    doc.text(fullName, 20, getPrefixLabelYPosition[index]);

    doc.setLineWidth(0.5);
    if (index < 2) {
      doc.setFillColor(246, 147, 33);
      doc.setDrawColor(246, 147, 33);
    } else {
      doc.setFillColor(153, 160, 159);
      doc.setDrawColor(153, 160, 159);
    }
    doc.roundedRect(75, barYPositions[index], 100 * (score / max), 10, 3, 2, 'FD');

    doc.setLineWidth(1);
    doc.setDrawColor(246, 147, 33);
    doc.setFillColor(255);
    doc.circle(75, circleYPositions[index], 6, 'FD');

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(20);
    doc.setTextColor(246, 147, 33); // Orange
    doc.text(value[0], getXPositionOfCircleLabel(value[0]), getPrefixLabelYPosition[index]);

    index += 1;
  }
};

/* istanbul ignore next */
const addExplanation = (doc) => {
  const titleLabelPositions = [40, 80, 120, 160, 200, 240];

  const generalGroupDescriptions = {
    A: 'Als artistiek type gebruik jij je handen en je hoofd om dingen te creeren. Je hebt veel ideeen en vindt het heerlijk om deze op artistieke wijze uit te werken. Je hebt een rijke fantasie die je graag gebruikt om te schrijven, ontwerpen, vormgeven, filmen of bijvoorbeeld muziek te maken. Daarom past een opleiding waarin je creatief bezig bent goed bij jouw talenten! ',
    O: 'Als ondernemend type zoek jij een studie met avontuur. Jij staat voorop wanneer er een nieuw project aangepakt moet worden. Jij bent goed in het ‘managen’ van dingen. Wanneer je iets gedaan moet krijgen, kan je andere mensen overtuigen van jouw plan. Stilzitten is voor jou geen optie, als ondernemend type zoek jij in jouw opleiding veel actie en afwisseling!',
    S: 'Als sociaal type heb jij een sterke voorkeur om activiteiten met en voor mensen te doen. Je krijgt positieve energie van helpen, luisteren, zorgen, adviseren en met mensen zijn. Je maakt makkelijk vrienden en vindt het belangrijk dat iedereen zich goed voelt in een groep. In jouw opleiding is het belangrijk dat je iets kan betekenen voor de medemens en de maatschappij.  Wat je ook doet, zolang je een steentje kan bijdragen voelt het voor jou goed!',
    R: 'Als realistisch type houd je ervan om je handen uit de mouwen te steken. Door middel van doen in plaats van alleen maar denken, ontdek jij de wereld om je heen. Je vindt het leuk om fysiek aan de slag te gaan en/of jouw technische inzicht in te zetten. Voor jou is zichtbaar resultaat belangrijk.',
    I: 'Als inhoudelijk type houd je ervan om dingen om je heen te onderzoeken door er veel over te leren, te lezen, te kijken of te luisteren. Zolang jij de kennis maar verkrijgt waar je naar op zoek bent! Een opleiding waar je mag onderzoeken, verdiepen en je kennis kan vergroten, zal bij jou passen.',
    C: 'Als conventioneel type houd jij ervan om goed voorbereid te zijn! Je wilt alles tot in de puntjes  georganiseerd hebben. Zo wil je jouw opleidingskeuze van tevoren goed doordacht hebben, en zal je op zoek gaan naar mensen die hun ervaringen met je delen. Zekerheid en stabiliteit is voor jou belangrijk. Je houdt van controle, orde, structuur, regels en betrouwbaarheid.',
  };

  let index = 0;
  const riasoc = ['R', 'I', 'A', 'S', 'O', 'C'];

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const value of riasoc) {
    const fullName = translateLetterToRiasoc(value);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(20);
    doc.setTextColor(246, 147, 33); // Orange
    doc.text(`${value}: ${fullName}`, 20, titleLabelPositions[index]);

    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 175, 230); // Blue
    doc.line(20, titleLabelPositions[index] + 2, 185, titleLabelPositions[index] + 4);

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(11);
    doc.setTextColor(0);
    const splitTitle = doc.splitTextToSize(generalGroupDescriptions[value], 170);

    doc.text(splitTitle, 20, titleLabelPositions[index] + 7, 'left');

    index += 1;
  }
};

/* istanbul ignore next */
const obtainDataUrl = (url) => {
  return new Promise((resolve) => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = () => {
      const reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = (e) => {
        resolve(e.target.result);
      };
    };
    request.send();
  });
};

/* istanbul ignore next */
const prepareQAForPDF = (allQA) => {
  const noAnswers = allQA.filter((qa) => qa.answer.answer === false);
  const yesAnswers = allQA.filter((qa) => qa.answer.answer === true);

  const answersInOrder = [];
  let currentlyNoArray = true;
  while (yesAnswers.length > 0 || noAnswers.length > 0) {
    let toPick = 2;
    if (currentlyNoArray) {
      while (toPick > 0) {
        if (noAnswers.length > 0) {
          answersInOrder.push(noAnswers.pop());
        } else {
          answersInOrder.push(null);
        }
        toPick -= 1;
      }
      currentlyNoArray = false;
    } else {
      while (toPick > 0) {
        if (yesAnswers.length > 0) {
          answersInOrder.push(yesAnswers.pop());
        } else {
          answersInOrder.push(null);
        }
        toPick -= 1;
      }
      currentlyNoArray = true;
    }
  }

  return answersInOrder;
};

/* istanbul ignore next */
const addFreshIdentiteitPage = (doc, baseY) => {
  doc.addPage('a4');
  doc.setLineWidth(0.5);
  doc.setDrawColor(225, 227, 227); // Blue
  doc.line(104.5, baseY - 20, 104.5, 280);
  doc.line(10, baseY - 5, 95, baseY - 5);
  doc.line(115, baseY - 5, 200, baseY - 5);

  doc.setFont('helvetica', 'bold');
  doc.setFontSize(20);

  doc.setTextColor(198, 74, 58); // Red
  doc.text('NEE, dit past niet bij mij', 15, baseY - 10);
  doc.setTextColor(58, 182, 74); // Green
  doc.text('JA, dit past bij mij', 122, baseY - 10);
};

/* istanbul ignore next */
const generateRIASOCReport = async (riasocCounts, personName) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const images = require.context('@/assets/images', false, /\.png$|\.jpg$/);

    const doc = new jsPDF({ compress: true });

    const logoImage = await obtainDataUrl(
      images('./fade_kieswijs_logo.png'),
    );
    doc.addImage(logoImage, 'png', 60, -40, 180, 199);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(25);
    doc.setTextColor(0, 175, 230); // Light Blue
    doc.text('RIASOC Resultaten', 20, 25);

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(16);
    doc.setTextColor(246, 147, 33); // Orange
    doc.text(personName, 20, 35);

    addBars(doc, riasocCounts);

    doc.addPage('a4');
    doc.addImage(logoImage, 'png', 0, 90, 180, 199);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(30);
    doc.setTextColor(0, 175, 230); // Light Blue
    doc.text('RIASOC Uitleg', 20, 25);
    addExplanation(doc);

    doc.save(`RIASOC-Resultaten-${personName}.pdf`);
    resolve();
  });
};

/* istanbul ignore next */
const generateIdenteit = async (allQA, personName) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const images = require.context('@/assets/images', false, /\.png$|\.jpg$/);

    const doc = new jsPDF({ compress: true });

    const logoImage = await obtainDataUrl(
      images('./fade_kieswijs_logo.png'),
    );
    doc.addImage(logoImage, 'png', 60, -40, 180, 199);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(25);
    doc.setTextColor(0, 175, 230); // Light Blue
    doc.text('Identiteit Resultaten', 20, 25);

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(16);
    doc.setTextColor(246, 147, 33); // Orange
    doc.text(personName, 20, 35);

    // a4 pageSize 210mm * 297mm
    const baseX = 0;
    const baseY = 30;
    const margin = 10;
    const widthHeight = 40;

    addFreshIdentiteitPage(doc, baseY);

    const answersInOrder = prepareQAForPDF(allQA);

    let col = 0;
    let row = 0;
    while (answersInOrder.length !== 0) {
      const qa = answersInOrder.shift();
      if (qa !== null) {
        // eslint-disable-next-line no-await-in-loop
        const image = await obtainDataUrl(
          qa.question.backImage,
        );

        const xPosition = col * widthHeight + ((1 + col) * margin) + baseX;
        const yPosition = row * widthHeight + ((1 + row) * margin) + baseY;
        doc.addImage(image, 'png', xPosition, yPosition, widthHeight, widthHeight);
      }

      if (col <= 2) {
        col += 1;
      } else {
        col = 0;

        if (row < 4) {
          row += 1;
        } else {
          // Add a pdf page
          if (answersInOrder.length !== 0) {
            addFreshIdentiteitPage(doc, baseY);
          }

          // And start over
          row = 0;
        }
      }
    }
    doc.save(`Identiteit-Resultaten-${personName}.pdf`);
    resolve();
  });
};




const addTitleFreshPage = (doc, titleText) => {
  doc.addPage('a4');
  doc.setLineWidth(0.5);

  doc.setFont('helvetica', 'bold');
  doc.setFontSize(20);
  doc.setDrawColor(225, 227, 227);
  doc.text(titleText, 35, 25);
};

const addWaardenPage = async (doc, titleText, waarden) => {
  // a4 pageSize 210mm * 297mm
  const baseX = 0;
  const baseY = 30;
  const margin = 10;
  const widthHeight = 40;

  addTitleFreshPage(doc, titleText);

  let col = 0;
  let row = 0;
  let index = 0;

  while (index < waarden.length) {
    const qa = waarden[index];
    if (qa !== null) {
      // eslint-disable-next-line no-await-in-loop
      const image = await obtainDataUrl(
        qa.question.frontImage,
      );

      const xPosition = col * widthHeight + ((1 + col) * margin) + baseX;
      const yPosition = row * widthHeight + ((1 + row) * margin) + baseY;
      doc.addImage(image, 'png', xPosition, yPosition, widthHeight, widthHeight);
    }

    if (col <= 2) {
      col += 1;
    } else {
      col = 0;

      if (row < 4) {
        row += 1;
      } else {
        // Add a pdf page
        if (index < waarden.length) {
          doc.addPage('a4');
        }

        // And start over
        row = 0;
      }
    }

    index += 1;
  }
};


/* istanbul ignore next */
const generateWaardenReport = async (selectedQA, notFittingQA, personName) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const images = require.context('@/assets/images', false, /\.png$|\.jpg$/);

    const doc = new jsPDF({ compress: true });

    const logoImage = await obtainDataUrl(
      images('./fade_kieswijs_logo.png'),
    );
    doc.addImage(logoImage, 'png', 60, -40, 180, 199);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(25);
    doc.setTextColor(0, 175, 230); // Light Blue
    doc.text('Waardenspel Resultaten', 20, 25);

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(16);
    doc.setTextColor(246, 147, 33); // Orange
    doc.text(personName, 20, 35);

    await addWaardenPage(doc, 'Dit zijn jouw waarden!', selectedQA);
    await addWaardenPage(doc, 'Deze waarden passen totaal niet bij jou!', notFittingQA);

    doc.save(`Waardenspel-Resultaten-${personName}.pdf`);
    resolve();
  });
};

export {
  generateRIASOCReport,
  generateIdenteit,
  generateWaardenReport,
  translateLetterToRiasoc,
  getXPositionOfCircleLabel,
};
