<template>
  <div>
    <div class="header-container">
      <h1>Contactformulier</h1>
    </div>

    <div class="body-container text-center">
      <p>
        Heb je behoefte aan een persoonlijk gesprek en wil je je dieper inzicht in je
        persoonlijkheid krijgen, neem dan contact op met één van de Kieswijsadviseurs.
        De adviseur zal je vragen nog een persoonlijkheidstest te doen om het
        advies te finetunen.
      </p>
      <p>
        Het gesprek met jou en je ouders duurt 1,5 à 2 uur.
      </p>
      <p>
        <strong>Kosten van het gesprek:</strong> €250 ex btw.
      </p>

      <form class="contact-form">
        <input
          type="name"
          name="name"
          id="name"
          placeholder="Naam*"
          v-model="name"
          required
        />
        <input
          type="address"
          name="address"
          id="address"
          placeholder="Adres*"
          v-model="address"
          required
        />
        <input
          type="date"
          name="bday"
          id="birthday"
          v-model="birthday"
          placeholder="DD-MM-JJJJ*"
          required
        />
        <select name="Schoolniveau" v-model="selectedSchoolLevel" id="schoolLevel" required>
          <option value="" disabled selected>Selecteer een niveau*</option>
          <option value="VMBO BBL">VMBO BBL</option>
          <option value="VMBO GL">VMBO GL</option>
          <option value="VMBO KBL">VMBO KBL</option>
          <option value="VMBO TL">VMBO TL</option>
          <option value="HAVO">HAVO</option>
          <option value="VWO">VWO</option>
        </select>
        <select name="Klas" v-model="selectedYear" id="selectedYear" required>
          <option value="" disabled selected>Selecteer een jaar*</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option v-if="selectedSchoolLevel == 'HAVO' ||
          selectedSchoolLevel == 'VWO'" value="5">5</option>
          <option v-if="selectedSchoolLevel == 'VWO'" value="6">6</option>
        </select>
        <input
          placeholder="Profiel*"
          v-model="educationType"
          id="educationType"
          required
        />
        <input
          type="tel"
          name="tel"
          id="phoneNumber"
          placeholder="Telefoonnummer*"
          v-model="phoneNr"
          required
        />
        <input
          type="tel"
          name="tel"
          id="phoneNumberParents"
          placeholder="Telefoonnummer ouders*"
          v-model="parentPhoneNr"
        />
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email*"
          v-model="email"
          required
        />
        <input
          type="email"
          name="emailParents"
          id="emailParents"
          placeholder="Email ouders*"
          v-model="emailParents"
          required
        />

        <p>Veleden met een * zijn verplicht</p>
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="privacyCheckbox"
            v-model="privacyAgreement"
          />
          <p class="text-left">
            Ik ga akkoord met de
            <a
              class="checkbox-accompanying-text"
              @click="$router.push({name: 'PrivacyPolicy'})"
            >
              privacyvoorwaarden
            </a>
          </p>
        </div>

        <button
          type="button"
          class="primary-button-orange"
          @click="submitForm">Verstuur
        </button>
      </form>
    </div>
    <v-dialog/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EmailAPI } from '../../../Shared/index';
import { firestore } from '../../../Shared/lib/firebase';

export default {
  name: 'ContactForm',
  data() {
    return {
      name: '',
      address: '',
      birthday: '',
      selectedSchoolLevel: '',
      selectedYear: '',
      educationType: '',
      phoneNr: '',
      parentPhoneNr: '',
      email: '',
      emailParents: '',
      privacyAgreement: false,
    };
  },
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
    }),
  },
  mounted() {
    if (this.userInformation) {
      this.name = `${this.userInformation.firstName} ${this.userInformation.lastName}`;
    }
  },
  methods: {
    submitForm() {
      if (this.textFieldFilledInCorrectly()) {
        EmailAPI.create({
          name: this.name,
          address: this.address,
          birthday: this.birthday,
          selected_school_level: this.selectedSchoolLevel,
          selected_year: this.selectedYear,
          education_type: this.educationType,
          phone_nr: this.phoneNr,
          parent_phone_nr: this.parentPhoneNr,
          email: this.email,
          email_parents: this.emailParents,
          answered: false,
          creation_date: firestore.FieldValue.serverTimestamp(),
        }).then(() => {
          this.$modal.show('dialog', {
            title: 'Gelukt',
            text: 'Het bericht is verstuurd naar kiesreis.nl\n Je ontvangt zo '
                + 'spoedig mogelijk een bericht!',
            buttons: [{
              title: 'Oké',
              handler: () => {
                this.$router.push({
                  name: 'Results',
                  params: { testIdentifier: 'unk' },
                });
              },
            }],
          });
        }).catch(() => {
          this.$toasted.error('Er ging iets fout tijdens het versturen van het formulier. Probeer het opnieuw.');
        });

        return;
      }

      this.$toasted.error('Niet alle velden zijn goed ingevuld');
    },
    textFieldFilledInCorrectly() {
      // Test Emails
      const emailCorrect = this.validateEmail(this.email);
      const parentEmailCorrect = this.validateEmail(this.emailParents);

      this.markIncorrectFields(emailCorrect, 'email');
      this.markIncorrectFields(parentEmailCorrect, 'emailParents');

      // Test Phone Numbers
      const phoneNrCorrect = this.validatePhoneNumber(this.phoneNr);
      const parentPhoneNrCorrect = this.validatePhoneNumber(this.parentPhoneNr);

      this.markIncorrectFields(phoneNrCorrect, 'phoneNumber');
      this.markIncorrectFields(parentPhoneNrCorrect, 'phoneNumberParents');

      // Test Date
      const dateCorrect = this.validateDate(this.birthday);
      this.markIncorrectFields(dateCorrect, 'birthday');

      // Check policy checkmark
      if (!this.privacyAgreement) {
        this.$toasted.error('Om contact op te kunnen nemen moet u eerst accoord gaan met '
                  + 'de privacyvoorwaarden');
      }

      // Check other fields on non null
      this.markIncorrectFields(this.name, 'name');
      this.markIncorrectFields(this.address, 'address');
      this.markIncorrectFields(this.selectedSchoolLevel, 'schoolLevel');
      this.markIncorrectFields(this.selectedYear, 'selectedYear');
      this.markIncorrectFields(this.educationType, 'educationType');

      return (this.name && this.address && this.selectedSchoolLevel
          && this.selectedYear && this.educationType && this.privacyAgreement
          && emailCorrect && parentEmailCorrect && phoneNrCorrect && parentPhoneNrCorrect
          && dateCorrect);
    },
    validateEmail(mail) {
      /* eslint-disable no-useless-escape */
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);
    },
    validatePhoneNumber(phoneNr) {
      return /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/.test(phoneNr);
    },
    validateDate(date) {
      return /^([0-2][0-9]|(3)[0-1])(-|\/)(((0)[0-9])|((1)[0-2]))(-|\/)\d{4}$/.test(date)
          || /^\d{4}(-|\/)([0-2][0-9]|(3)[0-1])(-|\/)(((0)[0-9])|((1)[0-2]))$/.test(date)
          || /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(date);
      /* eslint-enable no-useless-escape */
    },
    markIncorrectFields(isCorrect, fieldId) {
      const field = document.getElementById(fieldId);
      if (isCorrect) {
        this.removeInputWarning(field);
      } else {
        this.addInputWarning(field);
      }
    },
    addInputWarning(element) {
      element.classList.add('input-warning');
    },
    removeInputWarning(element) {
      element.classList.remove('input-warning');
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.contact-form
  border: 3px solid $blue
  @include xs-max
    margin: 10px
    padding: 10px
  @include sm
    margin: 20px
    padding: 40px

.primary-button-orange
  margin-top: 40px
</style>
