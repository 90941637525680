<template>
  <div id="app">
    <navigation-bar/>
    <div class="layered-background">
      <div class="parralax-image" id="parralax1" ref="parralax1"/>
      <div class="parralax-image" id="parralax2" ref="parralax2"/>
    </div>
    <router-view/>
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import ifvisible from 'ifvisible.js';

import { Authentication } from '../../Shared/index';

export default {
  name: 'App',
  components: {
    NavigationBar,
  },
  methods: {
    scrollFunc() {
      const windowScroll = window.scrollY;
      const level1 = this.$refs.parralax1;
      if (level1) {
        level1.style.transform = `translateY(-${windowScroll / 20}%)`;
      }

      const level2 = this.$refs.parralax2;
      if (level2) {
        level2.style.transform = `translateY(-${windowScroll / 40}%)`;
      }
    },
    enableWakeup() {
      // We remove any old event triggers
      ifvisible.off('wakeup');

      // Then we add a trigger to keep track of sign in/out events in other windows
      ifvisible.on('wakeup', () => {
        this.checkAuthentication();
      });
    },
    checkAuthentication() {
      // Check if the CSST token exists
      if (Authentication.getCsstCookie() === null && Authentication.userIsSignedIn()) {
        this.isLoading = true;
        // If not, and if we are signed in we want to log out
        Authentication.signOut()
          .then(() => {
            this.isLoading = false;
            if (this.$router.currentRoute.name !== 'Home') {
              this.$router.push({ name: 'Home' });
              this.$store.dispatch('riasocResults/flushData');
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else if (Authentication.getCsstCookie() === null && !Authentication.userIsSignedIn()) {
        this.isLoading = false;
        this.$store.dispatch('user/fetchUser', null);
      } else if (Authentication.getCsstCookie() !== null && !Authentication.userIsSignedIn()) {
        this.isLoading = true;
        // If it does, and we are not signed in, we want to sign in
        Authentication.signInIfCsstTokenPresent()
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
  },
  mounted() {
    // First we check if we should already sign in the user based on the CSST token
    Authentication.signInIfCsstTokenPresent()
      .then(() => {
        this.enableWakeup();
      })
      .catch(() => {
        this.enableWakeup();
      });
  },
};
</script>

<style lang="sass" scoped>
.layered-background
  z-index: -1
  position: absolute
  width: 100%
  top: 0px

  .parralax-image
    position: absolute
    top: 0
    width: 100%
    height: 100vh

#parralax1
  opacity: 1.0
  background-image: url("./assets/images/parralax-bg-1.png")

#parralax2
  opacity: 0.5
  background-image: url("./assets/images/parralax-bg-2.png")

$number-of-parralax-backgrounds: 2
@for $i from 1 through $number-of-parralax-backgrounds
  .parralax-image:nth-child(#{$i})
    z-index: $number-of-parralax-backgrounds - $i
</style>
