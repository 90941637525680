<template>
  <transition name="fade">
    <div class="container" v-if="numberOfQuestions != 0">
      <h2 class="text-center">
        Hier volgen {{ numberOfQuestions }} stellingen. Kijk, lees en KIESWIJS!
      </h2>

      <div class="cards-container">
        <explanation-card
          :headerText=noCardHeaderText
          secondaryText="Swipe naar links of klik op de knop"
          symbolName="cardSwiping/declineButtonImage.png"
          alt="Dit is niets voor mij"
        />

        <explanation-card
          :headerText=yesCardHeaderText
          secondaryText="Swipe naar rechts of klik op de knop"
          symbolName="cardSwiping/acceptButtonImage.png"
          alt="Dit lijkt mij leuk"
        />
      </div>

      <h1>ready for takeoff?</h1>

      <button class="primary-button-orange" @click="initializeTest">
        <span v-if="!cardsLoading">YES!</span>
        <beat-loader v-if="cardsLoading" color="white"></beat-loader>
      </button>

      <button class="secondary-blue-button" @click="disclaimerVisible = true">
        Disclaimer
      </button>

      <transition name="fade">
        <div class="modal-overlay" v-if="disclaimerVisible">
          <div>
            <p>
              Disclaimer: De constructie en implementatie van het Kieswijs
              Analyse Systeem (KAS) is volgens wetenschappelijke princiepes
              en best practice gebeurd. Desondanks kan Kieswijs
              niet verantwoordelijk worden gesteld voor de consequenties
              en beslissingen die de lezer naar aanleiding van het rapport neemt.
              Voor alle onderdelen van KAS geldt &copy; 2012 - 2021, Kieswijs.
            </p>
            <button class="secondary-blue-button" @click="disclaimerVisible = false">
              Sluiten
            </button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
// eslint-disable-next-line import/extensions
import { BeatLoader } from 'vue-spinner/dist/vue-spinner.min.js';
import ExplanationCard from '@/components/swipeableCards/ExplanationCard.vue';

export default {
  name: 'SwipingExplanationView',
  data() {
    return {
      noCardHeaderText: '',
      yesCardHeaderText: '',
      disclaimerVisible: false,
    };
  },
  props: {
    numberOfQuestions: {
      type: Number,
      requited: true,
    },
    cardsLoading: {
      type: Boolean,
      requited: true,
    },
  },
  components: {
    ExplanationCard,
    BeatLoader,
  },
  methods: {
    initializeTest() {
      if (!this.cardsLoading) {
        this.$emit('startTestClicked');
        // Remove any old answers, if there are any
        this.$store.dispatch('testData/reset');
      }
    },
  },
  mounted() {
    if (this.$route.params.type === 'riasoc_kiesreis') {
      this.noCardHeaderText = 'Nee! Dit is niets voor mij.';
      this.yesCardHeaderText = 'Ja! Dit lijkt mij leuk om te doen!';
    }
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.container
  @include flex-column(center)
  align-items: center
  text-align: center

  & button
    margin-bottom: 20px

h2
  @include xs-max
    margin: 15px 20px
  @include sm
    width: 450px

.cards-container
  @include xs-max
    @include flex-column(center)
    align-items: center
  @include sm
    @include flex-row(center)

.primary-button-orange
  @include xs-max
    width: 90vw
  @include sm
    width: 300px

.warning-box
  background-color: rgba(255,255,255,0.5)
  color: $red
  margin: 0 10px
  padding: 10px

.secondary-blue-button
  font-size: 1em

.modal-overlay
  @include flex-column(center)
  align-items: center
  position: fixed
  width: 100vw
  height: 100vh
  background-color: rgba(black, 0.3)
  div
    background-color: white
    border-radius: 4px
    padding: 2em
    max-width: 500px
    margin: 1em
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.30)
    -moz-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.30)
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.30)
</style>
