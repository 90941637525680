const actions = {
  reset(context) {
    context.commit('reset');
  },
};

const mutations = {
  reset(state) {
    state.testAnswers = [];
    state.testName = '';
  },
  addAnswer(state, answer) {
    state.testAnswers.push(answer);
  },
  setTestName(state, name) {
    state.testName = name;
  },
};

export default {
  namespaced: true,
  state: {
    testAnswers: [],
    testName: '',
  },
  mutations,
  actions,
};
