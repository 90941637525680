<template>
  <div class="cards-buttons-container">
    <div class="cards-container">
      <h2>Dat waren ze!</h2>
      <div class="cards">
        <div v-if="$route.params.type === 'riasoc_kiesreis'">
          <game-card
            v-for="(card, index) in cards"
            :ref="'gameCard-' + card"
            :key="card.question"
            :card="card"
            :is-current="index == 0"
            @cardAccepted="$emit('cardAccepted', card)"
            @cardRejected="$emit('cardRejected', card)"
            @hideCard="$emit('hideCard')"
            @showingBack="enableActionButtons"
          />
        </div>
      </div>
    </div>

    <div class="button-container" ref="buttonContainer">
      <action-button
        symbolName="cardSwiping/declineButtonImage.png"
        alt="Dit is niets voor mij"
        @clicked="declineButtonClicked"
      />
      <action-button
        symbolName="cardSwiping/acceptButtonImage.png"
        alt="Dit lijkt mij leuk"
        @clicked="acceptButtonClicked"
      />
    </div>
  </div>
</template>

<script>
import GameCard from '@/components/swipeableCards/GameCard.vue';
import ActionButton from '@/components/swipeableCards/ActionButton.vue';

const ACCEPT_CARD = 'cardAccepted';
const REJECT_CARD = 'cardRejected';

export default {
  name: 'GameCardStack',
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
  components: {
    GameCard,
    ActionButton,
  },
  methods: {
    enableActionButtons() {
      this.$refs.buttonContainer.classList.remove('disabled-cards-container');
    },
    acceptButtonClicked() {
      this.performActionOnCards(ACCEPT_CARD);
    },
    declineButtonClicked() {
      this.performActionOnCards(REJECT_CARD);
    },
    performActionOnCards(action) {
      if (this.cards.length > 0) {
        const firstCard = this.cards[0];
        const cardObjects = this.$refs[`gameCard-${firstCard}`];
        if (cardObjects.length > 0) {
          const cardObject = cardObjects[0];
          cardObject.playCard(action);
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.button-container
  @include flex-row(center)

.cards-container
  @include flex-row(center)
  & h2
    background-color: $gray-light
    border-radius: 10px
    padding: 15px
    position: absolute
    top: 38%

.disabled-cards-container
  opacity: 0.2
  pointer-events: none

.cards
  height: 400px
  margin-top: 30px
  position: relative
  @include xs-max
    width: 275px
    height: 350px
  @include sm
    width: 350px
    height: 500px

</style>
