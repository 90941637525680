import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../pages/Home.vue';
import TestEnvironment from '../pages/TestEnvironment.vue';
import Account from '../pages/Account.vue';
import Results from '../pages/Results.vue';
import ContactForm from '../pages/ContactForm.vue';
import PrivacyPolicy from '../pages/PrivacyPolicy.vue';
import ErrorPage from '../pages/ErrorPage.vue';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  }, {
    path: '/test-environment/:type',
    name: 'TestEnvironment',
    component: TestEnvironment,
  }, {
    path: '/account',
    name: 'Account',
    component: Account,
  }, {
    path: '/results/:testIdentifier',
    name: 'Results',
    component: Results,
  }, {
    path: '/contact',
    name: 'ContactForm',
    component: ContactForm,
  }, {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  }, {
    path: '*',
    name: 'ErrorPage',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.state.user.id;
  if (to.fullPath === '/results' || to.fullPath === '/contact' || to.fullPath === '/myAccount') {
    if (!isLoggedIn) {
      next('/account');
    }
  } else if (to.fullPath === '/staffDashboard') {
    if (!(store.state.user.isAdmin)) {
      next('/account');
    }
  }
  next();
});

export default router;
