<template>
  <div>
    <div class="header-container">
      <div v-if="!emptyResults">
        <h1 v-if="userInformation">
          {{ userInformation.firstName
            + ' '
            + userInformation.lastName
          }}, jouw Kiesreistype is</h1>

        <div class="result-bubbles-container">
          <div class="result-bubble text-center" v-if="characteristic_1">
            <h1>{{ characteristic_1 }}</h1>
          </div>

          <div class="result-bubble text-center" v-if="characteristic_2">
            <h1>{{ characteristic_2 }}</h1>
          </div>
        </div>
      </div>

      <div v-if="emptyResults && userInformation">
        <h1>{{ userInformation.firstName + ' ' + userInformation.lastName }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RiasocResultsHeader',
  data() {
    return {
      characteristic_1: null,
      characteristic_2: null,
      emptyResults: false,
    };
  },
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
      riasocResults: 'riasocResults/riasocResults',
    }),
  },
  watch: {
    riasocResults(newValue) {
      this.emptyResults = newValue.emptyResults;
      this.characteristic_1 = newValue.characteristic_1;
      this.characteristic_2 = newValue.characteristic_2;
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.result-bubbles-container
  @include flex-row(center)
</style>
