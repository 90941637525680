import { render, staticRenderFns } from "./SwipingExplanationView.vue?vue&type=template&id=03a86896&scoped=true&"
import script from "./SwipingExplanationView.vue?vue&type=script&lang=js&"
export * from "./SwipingExplanationView.vue?vue&type=script&lang=js&"
import style0 from "./SwipingExplanationView.vue?vue&type=style&index=0&id=03a86896&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a86896",
  null
  
)

export default component.exports