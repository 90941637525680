<template>
  <div class="content-container">
    <button class="primary-button-orange" @click="showRiasocResults">
      RIASOC-Kiesreistype
    </button>
  </div>
</template>

<script>

export default {
  name: 'UnknownResultsBody',
  methods: {
    showRiasocResults() {
      this.routeToResults('riasoc_kiesreis');
    },
    routeToResults(type) {
      this.$router.push({
        name: 'Results',
        // eslint-disable-next-line object-shorthand
        params: { testIdentifier: type },
      });
      this.$emit('updatedResultsPageRoute', type);
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.content-container
  @include flex-column(center)
  @include sm-max
    width: 100vw
  @include md
    width: 100%
  & button
    margin: 20px
  & p
    margin: 20px

.primary-button-orange
  align-self: center
  @include sm-max
    width: 300px
  @include md
    width: 400px
</style>
