<template>
  <div
    :class="{
      centering: showingExplanation,
    }"
    @startTestClicked="startTest">

    <transition name="fade" mode="out-in">
      <div class="game-card-stack-container" v-if="!showingExplanation">
        <game-card-stack
          :cards="visibleCards"
          @cardAccepted="handleCardAccepted"
          @cardRejected="handleCardRejected"
          @hideCard="removeCardFromDeck"
        />
        <loading-progress
          :progress="this.cardNumberProgress/this.totalNumberOfCards"
          shape="line"
          size="200"
          width="200"
          height="6"
        />
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <swiping-explanation-view
        v-if="showingExplanation"
        :numberOfQuestions="numberOfQuestions"
        @startTestClicked="startTest"
        :cardsLoading="cardsLoading"
      />
    </transition>
  </div>
</template>

<script>
import GameCardStack from '@/components/swipeableCards/GameCardStack.vue';
import SwipingExplanationView from '@/components/swipeableCards/SwipingExplanationView.vue';
import { QuestionAPI } from '../../../Shared/index';

export default {
  name: 'TestEnvironment',
  data() {
    return {
      showingExplanation: true,
      visibleCards: [],
      cardNumberProgress: 0,
      totalNumberOfCards: 0,
      cardsLoading: true,
    };
  },
  components: {
    GameCardStack,
    SwipingExplanationView,
  },
  methods: {
    startTest() {
      this.showingExplanation = false;

      // To make sure the user is at the top of the page when the card swipe
      // shows up, scroll the user to the top of the page
      window.scrollTo(0, 0);
    },
    handleCardAccepted(card) {
      this.cardNumberProgress += 1;
      this.$store.commit(
        'testData/addAnswer',
        {
          qid: card.id,
          answer: true,
        },
      );
    },
    handleCardRejected(card) {
      this.cardNumberProgress += 1;
      this.$store.commit(
        'testData/addAnswer',
        {
          qid: card.id,
          answer: false,
        },
      );
    },
    removeCardFromDeck() {
      this.visibleCards.shift();
      this.checkForAndHandleEmptyCardStack();
    },
    checkForAndHandleEmptyCardStack() {
      if (this.visibleCards.length === 0) {
        const vue = this;
        setTimeout(() => {
          this.$store.commit(
            'testData/setTestName', this.$route.params.type,
          );
          vue.$router.push({ name: 'Account' });
        }, 2000);
      }
    },
    shuffleArray(array) {
      const dupArray = array;
      for (let i = dupArray.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * i);
        const temp = dupArray[i];
        dupArray[i] = dupArray[j];
        dupArray[j] = temp;
      }
      return dupArray;
    },
  },
  mounted() {
    this.cardsLoading = true;
    QuestionAPI.getAll('riasoc_kiesreis')
      .then((snapshot) => {
        let questions = [];

        snapshot.forEach((doc) => {
          questions = doc.data().questions.filter((question) => question.questionActive);
        });

        questions = this.shuffleArray(questions);
        this.totalNumberOfCards = questions.length;
        this.visibleCards = questions;
        this.cardsLoading = false;
      });
  },
  computed: {
    numberOfQuestions() {
      const count = this.visibleCards.length;
      return count;
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.game-card-stack-container
  height: 100vh
  @include flex-column(center)

.centering
  @include flex-column(center)
  @include xs-max
    padding-top: $navigation-bar-height
  @include sm
    height: 100vh

.fade-enter-active, .fade-leave-active
  transition: opacity 1s

.fade-enter, .fade-leave-to
  opacity: 0

.vue-progress-path
  align-self: center
</style>
