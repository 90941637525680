<template>
  <div class="">
    <scroll-down-hint v-if="!emptyResults"/>

    <div class="header-container">
      <riasoc-results-header v-if="testName === 'riasoc_kiesreis'"/>
      <unknown-results-header v-if="testName === 'unk'"/>
    </div>

    <transition name="fade" mode="out-in">
      <div class="body-container text-center" v-if="fullyLoaded">

        <riasoc-results-body v-if="testName === 'riasoc_kiesreis' && !emptyResults"/>
        <unknown-results-body
          v-if="testName === 'unk'"
          @updatedResultsPageRoute="reloadAnswers"/>

        <div v-if="emptyResults">
          <h1>Je hebt de test nog niet gedaan!</h1>
          <h4>
            Het kan ook zijn dat er niet genoeg resultaten
            zijn om een score te geven.
          </h4>
          <h3>Doe nu de test!</h3>
          <button
            class="primary-button-orange"
            @click="$router.push({name: 'TestEnvironment', params: { type: testName}})"
          >
            Start de test
          </button>
        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if="!fullyLoaded" class="loading-container">
        <scale-loader color="#F26000"></scale-loader>
      </div>
    </transition>
  </div>
</template>

<script>
import ScrollDownHint from '@/components/ScrollDownHint.vue';
import RiasocResultsHeader from '@/components/results/riasoc/RiasocResultsHeader.vue';
import RiasocResultsBody from '@/components/results/riasoc/RiasocResultsBody.vue';

import UnknownResultsHeader from '@/components/results/unknown/UnknownResultsHeader.vue';
import UnknownResultsBody from '@/components/results/unknown/UnknownResultsBody.vue';

import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'Results',
  data() {
    return {
      emptyResults: false,
      fullyLoaded: false,
    };
  },
  methods: {
    loadData() {
      this.fullyLoaded = false;
      this.isVisibleForUser = false;

      if (this.testName === 'riasoc_kiesreis') {
        this.$store.dispatch('riasocResults/retrieveData', this.userId);
      } else if (this.testName === 'unk') {
        this.emptyResults = false;
        this.fullyLoaded = true;
      }
    },
    reloadAnswers() {
      this.loadData();
    },
  },
  components: {
    RiasocResultsHeader,
    RiasocResultsBody,
    ScrollDownHint,
    UnknownResultsHeader,
    UnknownResultsBody,
    ScaleLoader,
  },
  computed: {
    ...mapGetters({
      userId: 'user/id',
      riasocResults: 'riasocResults/riasocResults',
    }),
    testName() {
      return this.$route.params.testIdentifier;
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    riasocResults(newValue) {
      if (this.testName === 'riasoc_kiesreis') {
        this.emptyResults = newValue.emptyResults;
        this.fullyLoaded = true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.header-container
  & h1
    padding: 10px
    text-align: center

.back-button
  margin-top: 2em

.loading-container
  @include flex-column(center)
  height: $body-height
  background-color: white
</style>
