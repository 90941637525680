<template>
  <div class="content-container">
    <scale-loader v-if="chartLoading" color="#F26000"></scale-loader>

    <chart
      v-if="!chartLoading"
      :riasocCounts="this.riasoc_counts"
    />

    <p v-if="!chartLoading">
      Op basis van jouw Kiesreistype, adviseren wij
      jou de volgende bestemmingen te bekijken!
    </p>
    <div v-if="!chartLoading" class="travel-buttons-container">
      <a
        :href="`${getKiesreisBaseUrl()}${this.characteristic_1_fullTitle}/`"
        target="_blank"
        class="primary-button-orange"
      >
        Check <br/> {{ this.characteristic_1_fullTitle }} reizen
      </a>
      <a
        :href="`${getKiesreisBaseUrl()}${this.characteristic_2_fullTitle}/`"
        target="_blank"
        class="primary-button-orange"
      >
        Check <br/> {{ this.characteristic_2_fullTitle }} reizen
      </a>
    </div>
    <a
      v-if="!chartLoading"
      href="https://www.kiesreis.com/bestemmingen/"
      target="_blank"
      class="secondary-button-orange"
    >
      Check alle reizen
    </a>

    <p
      v-if="!chartLoading"
      class="mt-2"
    >
      Heb je behoefte aan een personal touch?
    </p>
    <button
      v-if="!chartLoading"
      class="secondary-blue-button"
      @click="openContactForm"
    >
      Maak een afspraak
    </button>
  </div>
</template>

<script>
import Chart from '@/components/chart/Chart.vue';
import Constants from '@/constants';

import { mapGetters } from 'vuex';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

export default {
  name: 'RiasocResultsBody',
  data() {
    return {
      chartLoading: true,
      riasoc_counts: null,
      characteristic_1: null,
      characteristic_2: null,
      characteristic_1_fullTitle: '',
      characteristic_2_fullTitle: '',
      riasocFullTitles: {
        R: 'Realist',
        I: 'Inhoudelijk',
        A: 'Artistiek',
        S: 'Sociaal',
        O: 'Ondernemend',
        C: 'Conventioneel',
      },
    };
  },
  methods: {
    openContactForm() {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'ContactForm' });
    },
    setupLinkText() {
      const t1 = this.characteristic_1;
      const t2 = this.characteristic_2;
      this.characteristic_1_fullTitle = this.riasocFullTitles[t1];
      this.characteristic_2_fullTitle = this.riasocFullTitles[t2];
    },
    getKiesreisBaseUrl() {
      return Constants.KIESREIS_BASE_URL;
    },
    checkToFillChart(data) {
      this.riasoc_counts = data.riasoc_counts;
      this.characteristic_1 = data.characteristic_1;
      this.characteristic_2 = data.characteristic_2;
      this.chartLoading = false;
      this.setupLinkText();
    },
  },
  components: {
    Chart,
    ScaleLoader,
  },
  computed: {
    ...mapGetters({
      riasocResults: 'riasocResults/riasocResults',
    }),
  },
  mounted() {
    this.chartLoading = true;
    this.checkToFillChart(this.riasocResults);
  },
  watch: {
    riasocResults(newValue) {
      this.checkToFillChart(newValue);
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

a
  margin: 10px  !important
  text-decoration: none

.travel-buttons-container
  @include sm-max
    @include flex-column(space-between)
  @include md
    @include flex-row(space-between)
    flex-grow: 1

.content-container
  @include flex-column(center)
  @include sm-max
    width: 100vw
  & button
    margin: 20px
  & p
    margin: 20px

.primary-button-orange
  flex-basis: 100%
</style>
