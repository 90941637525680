<template>
  <div class="">
    <div class="header-container">
      <h1 class="big-title">
        CHECK JOUW KIESREISTYPE EN BESTEMMINGEN
      </h1>
      <p class="text-center" style="font-size:13px; padding: 2px" v-if="!loggedIn">
        Almost there! Maak een account aan of log in om jouw reisadvies te ontvangen.
      </p>
      <p class="text-center" style="font-size:13px; padding: 2px" v-if="loggedIn">
        We slaan nu jouw antwoorden op in je Mijn Kieswijs account.
      </p>
    </div>

    <div class="body-container text-center">
      <div class="collumn" v-if="!loggedIn && !isLoading">
        <button
          class="primary-button-orange standard-button-shadow sign-in-button"
          @click="signIn">
          Inloggen bij Mijn Kieswijs!
        </button>
      </div>

      <div class="collumn loading-column" v-if="loggedIn || isLoading">
        <scale-loader color="#F26000"></scale-loader>
      </div>

      <div class="collumn hide-on-small-screen">
        <img src="../assets/images/home/account-image.png" alt="Account">
      </div>
    </div>
  </div>
</template>

<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import { mapGetters } from 'vuex';

import { firestore } from '../../../Shared/lib/firebase';
import { AnswerAPI, Authentication } from '../../../Shared/index';

export default {
  name: 'Account',
  // eslint-disable-next-line arrow-body-style
  data: () => {
    return {
      isLoading: false,
    };
  },
  methods: {
    signIn() {
      this.isLoading = true;
      Authentication.authenticateIfRequired()
        .then(() => {
          // The authentication might take some time, so we wait with removing
          // the loading screen
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    storeAnswers() {
      if (this.$store.state.testData.testAnswers.length > 0) {
        let testIdentifier = this.$store.state.testData.testName;
        if (testIdentifier === null || testIdentifier === '' || testIdentifier === undefined) {
          testIdentifier = 'unk';
        }

        AnswerAPI.retrieveAll(this.$store.state.user.id, testIdentifier)
          .then((snapshot) => {
            if (snapshot.empty) {
              this.storeAnswersAsNew(testIdentifier);
            } else {
              snapshot.forEach((doc) => {
                this.storeAnswersAsUpdate(testIdentifier, doc.id);
              });
            }
          })
          .catch((error) => {
            console.log(`Error ${error}`);
            this.$toasted.error('Er is iets fout gegeaan');
          });
      } else {
        this.$router.push({
          name: 'Results',
          params: { testIdentifier: 'riasoc_kiesreis' },
        });
      }
    },
    storeAnswersAsNew(testIdentifier) {
      AnswerAPI.create({
        user: this.$store.state.user.id,
        all_answers: this.$store.state.testData.testAnswers,
        question_type: testIdentifier,
        upload_date: firestore.FieldValue.serverTimestamp(),
        is_visible_for_user: true,
      })
        .then(() => {
          // TODO ADD SUBMISSION LOG?????
          this.$store.dispatch('testData/reset');
          this.$router.push({
            name: 'Results',
            // eslint-disable-next-line object-shorthand
            params: { testIdentifier: testIdentifier },
          });
        })
        .catch(() => {
          this.$toasted.error('Er is iets fout gegaan');
        });
    },
    storeAnswersAsUpdate(testIdentifier, answerSubmissionId) {
      AnswerAPI.update(answerSubmissionId, {
        all_answers: this.$store.state.testData.testAnswers,
        upload_date: firestore.FieldValue.serverTimestamp(),
        is_visible_for_user: true,
      })
        .then(() => {
          this.$store.dispatch('testData/reset');
          this.$router.push({
            name: 'Results',
            // eslint-disable-next-line object-shorthand
            params: { testIdentifier: testIdentifier },
          });
        })
        .catch(() => {
          this.$toasted.error('Er is iets fout gegaan');
        });
    },
  },
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
    }),
  },
  watch: {
    loggedIn(value) {
      if (value) {
        this.storeAnswers();
      }
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.storeAnswers();
    }
  },
  components: {
    ScaleLoader,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.body-container
  @include flex-row(center)

.collumn
  @include flex-column(center)
  & img
    height: 175px
    width: 175px
  @include md
    margin-left: 20px

.loading-column
  width: 175px

.sign-in-button
  font-size: 1em
  height: 100px

</style>
