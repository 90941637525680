<template>
  <div class="">
    <div class="header-container">
      <h1 class="big-title">Sorry, er is iets fout gegaan</h1>

    </div>

    <div class="body-container text-center">
      <p>
        Ga terug naar de vorige pagina. Weet je niet meer waar je bent? Ga dan terug naar de
        home pagina.
      </p>

      <div class="button-container">
        <button class="primary-button-orange" @click="$router.go(-1)">Terug</button>
        <button class="primary-button-orange" @click="$router.push({ name: 'Home' })">
          Home
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ErrorPage',
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.button-container
  & button
    margin: 0 10px
</style>
