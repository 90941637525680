<template>
  <div class="header-container">
    <h1 class="big-title">Haal alles uit <u>jouw</u> tussenjaar</h1>
    <button
      v-if="!(userAnswered && loggedIn)"
      class="primary-button-orange"
      @click="$router.push({name: 'TestEnvironment', params: { type: 'riasoc_kiesreis'}})"
    >
      Start de test
    </button>

    <button
      v-if="userAnswered && loggedIn"
      class="primary-button-orange"
      @click="$router.push({
        name: 'Results',
        params: { testIdentifier: 'riasoc_kiesreis' },
      })"
    >
      Bekijk de Resultaten
    </button>

    <button
      v-if="userAnswered && loggedIn"
      class="secondary-button-orange secondary-header-button"
      @click="$router.push({name: 'TestEnvironment', params: { type: 'riasoc_kiesreis'}})"
    >
      Doe de test opnieuw
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AnswerAPI } from '../../../../Shared/index';

export default {
  name: 'riasocHero',
  data() {
    return {
      userAnswered: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
    }),
  },
  mounted() {
    if (this.loggedIn) {
      if (this.$store.state.user.id !== null) {
        AnswerAPI.getAllAnswersForUser(this.$store.state.user.id)
          .then((snapshot) => {
            if (!snapshot.empty) {
              this.userAnswered = true;
            }
          });
      }
    }
  },
};
</script>

<style lang="sass" scoped>
.secondary-header-button
  font-size: .8em
</style>
