import '@/helpers/vue-awesome-icons';
import '@/assets/styles/global.sass';

import Toasted from 'vue-toasted';
import VModal from 'vue-js-modal';
import 'vue-progress-path/dist/vue-progress-path.css';
import VueProgress from 'vue-progress-path';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import { auth } from '../../Shared/lib/firebase';

Vue.config.productionTip = false;

Vue.use(Toasted, {
  position: 'bottom-center',
  duration: 4000,
  fullWidth: true,
  className: 'toaster-error',
});
Vue.use(VModal, { dialog: true });
Vue.use(VueProgress);

Vue.prototype.$production = false;

auth.onAuthStateChanged((user) => {
  store.dispatch('user/fetchUser', user);
  if (user) {
    store.dispatch('user/loadUserData');
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
