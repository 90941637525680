<template>
  <div>
    <div class="hero-container">
      <riasoc-hero v-if="riasocVisible"/>
    </div>

    <div class="body-container text-center">
      <h2>
        Match jouw persoonlijkheid (Kiesreistype) aan een tussenjaar,
        zodat jij je optimaal kan ontwikkelen.
      </h2>
      <div class="image-container">
        <div class="text-img-container">
          <img src="@/assets/images/home/duration-image.png" alt="Lengte van de test">
          <p>De test duurt 3-5 minuten.</p>
        </div>

        <div class="text-img-container">
          <img src="../assets/images/home/account-image.png" alt="Account">
          <p>
            In jouw gratis Kiesreisaccount heb je toegang tot de
            testuitslag en de mogelijkheden voor een tussenjaar!
          </p>
        </div>

        <button
          class="primary-button-orange hidden-on-large-screen"
          @click="$router.push(
            {
              name: 'TestEnvironment',
              params: {
                type: currentTestTypeForSecondaryButton
              }
            }
          )"
        >
          Start de test
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import RiasocHero from '@/components/home/RiasocHero.vue';

export default {
  name: 'Home',
  data() {
    return {
      riasocVisible: true,
      currentTestTypeForSecondaryButton: 'riasoc-reistype',
    };
  },
  components: {
    RiasocHero,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/global.sass'

.hero-container
  @include flex-column(center)

.image-container
  @include xs-max
    @include flex-column(space-between)
    align-self: center
  @include sm
    @include flex-row(space-around)

  & img
    height: auto
    @include xs-max
      width: 100px
    @include sm
      width: 150px

.text-img-container
  width: 280px
  @include xs-max
    margin: 20px 10px

.switch-test-button
  align-self: center
  font-size: .8em
  margin-bottom: 20px
  @include sm-max
    width: 90vw
  @include md
    width: 60vw
    max-width: 400px
</style>
